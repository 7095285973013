:root {
  --header-height: 80px;

  --iveco-blue: #1554ff;
  --iveco-blue-dark: #0140e9;
  --white: #fff;
  --bg-gray: #eee;

  --side-offset: clamp(0.75rem, 0.075rem + 2.25vw, 1.875rem);
}

@media only screen and (max-width: 480px) {
  :root {
    --header-height: 60px;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
