.last-update-wrapper {
  position: absolute;
  left: var(--side-offset);
  display: flex;
  align-items: center;
  gap: 8px;
  z-index: 1;

  --max-width: 300px;
  --opacity: 1;
}

.last-update-wrapper button {
  padding: 0;
  margin: 0;
  border: none;
  display: flex;
  align-items: center;
  color: var(--iveco-blue);
  background: transparent;
  cursor: pointer;
}

.last-update-wrapper:before {
  content: "";
  position: absolute;
  opacity: var(--opacity);
  background: white;
  inset: -5px -20px -5px -5px;
  z-index: -1;
  border-radius: 40px;
  transition: opacity 0.3s;
}

.last-update-wrapper .info {
  width: max-content;
  max-width: var(--max-width);
  white-space: nowrap;
  overflow: hidden;
  transition: max-width 0.3s;
  font-size: 14px;
}

@media (max-width: 768px) {
  .last-update-wrapper {
    --max-width: 0px;
    --opacity: 0;
  }

  .last-update-wrapper:hover,
  .last-update-wrapper:active {
    --max-width: 300px;
    --opacity: 1;
  }
}
