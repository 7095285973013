.loaderWrapper {
  background: var(--iveco-blue);
  position: absolute;
  inset: 0;
  z-index: 1;
  transition: opacity 0.2s;
  opacity: 0;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: start;
}

.loader-container {
  margin-bottom: 4em;
}

.loader-container {
  margin-bottom: 4em;
}

.loaderWrapper.visible {
  opacity: 1;
  pointer-events: all;
}

.container {
  width: min(700px, 80%);
  aspect-ratio: 1 / 1;
  background: var(--iveco-blue);
  border-radius: 16px;
  display: flex;
  align-items: center;
  perspective: 250px;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.loader {
  width: 48px;
  height: 48px;
  display: block;
  margin: 15px auto;
  position: relative;
  color: #fff;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.loader::after,
.loader::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  width: 24px;
  height: 24px;
  top: 50%;
  left: 50%;
  transform: scale(0.5) translate(0, 0);
  background-color: #fff;
  border-radius: 50%;
  animation: animloader 1s infinite ease-in-out;
}

.loader::before {
  background-color: #ffffff;
  transform: scale(0.5) translate(-48px, -48px);
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes animloader {
  50% {
    transform: scale(1) translate(-50%, -50%);
  }
}

.phrase-container {
  color: var(--white);
  font-weight: 600;
  text-align: center;
  font-size: 30px;
  text-wrap: balance;
}

.h2-container {
  color: var(--white);
  font-weight: 800;
  text-align: center;
  font-size: 25px;
}

.loadingText {
  height: auto;
  margin-top: 2em;
  color: var(--white);
  font-weight: 400;
  text-align: center;
  font-size: 20px;
}
