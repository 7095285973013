.send-box {
  display: flex;
  background: transparent;
}

.send-box__input {
  font-size: 16px;
  padding: 24px 16px;
  border: none;
  flex-grow: 2;
  outline: none;
}

.send-box__button {
  aspect-ratio: 1 / 1;
  height: calc(100% - 16px);
  margin: 8px;
  border: none;
  background: var(--iveco-blue);
  pointer-events: none;
  border-radius: 9px;
  transition: background 0.3s;
  overflow: hidden;
}

.send-box__button svg {
  stroke: white;

  transform: translate(0, 2px) rotate(45deg);
  transition: stroke 0.5s, transform 0.5s;
}

.send-box__button.active {
  pointer-events: all;
  background: var(--iveco-blue);
  cursor: pointer;
}

.send-box__button.active svg {
  stroke: var(--white);
  transform: translate(0, 2px) rotate(0deg);
}

.send-box__button.active:hover {
  background: var(--iveco-blue-dark);
}

.send-box__button.active:hover svg {
  animation: send 2.5s infinite;
}

@keyframes send {
  0% {
    transform: translate(0, 2px) rotate(0deg);
  }

  25% {
    transform: translate(40px, -42px) rotate(20deg);
    animation-timing-function: step-end;
  }

  26% {
    transform: translate(-40px, 38px) rotate(20deg);
  }

  50% {
    transform: translate(0, 2px) rotate(0deg);
  }
}
