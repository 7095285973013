@media only screen and (max-width: 480px) {
  .chatWrapper > .react-container > section {
    min-width: 330px !important;
  }

  .react-container > section > header > span[aria-label="Refresh"] {
    margin-right: 12px;
    padding-right: 0;
  }
}

.chatWrapper {
  /* border: solid 1px #DDD;
  border-radius: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, .2); */
  height: 97svh;
}

.chatWrapper > .react-container > section {
  height: 85svh !important;
  margin: 0 auto !important;
  width: 98% !important;
  max-width: 1024px !important;
  min-width: 330px;
  overflow: hidden;
  position: relative;
}

header {
  background-color: white;
}

.react-container:not(.webchat) {
  padding: 20px;
}

.webchat__bubble__content {
  margin-bottom: 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.command-bar {
  padding-bottom: 20px;
}

.command-bar > :not(:first-child) {
  margin-left: 20px;
}

.webchat {
  height: calc(100% - var(--header-height));
}

.App-Banner {
  pointer-events: none;
  width: clamp(250px, 40vw, 330px);
}

.webchat--css-fmfny-1vap6r.webchat__stacked-layout.webchat__stacked-layout--hide-avatar
  .webchat__stacked-layout__attachment,
.webchat--css-fmfny-1vap6r.webchat__stacked-layout.webchat__stacked-layout--show-avatar
  .webchat__stacked-layout__attachment,
.webchat--css-fmfny-1vap6r.webchat__stacked-layout.webchat__stacked-layout--hide-nub
  .webchat__stacked-layout__attachment,
.webchat--css-fmfny-1vap6r.webchat__stacked-layout.webchat__stacked-layout--show-nub
  .webchat__stacked-layout__attachment,
.webchat--css-fmfny-1vap6r.webchat__stacked-layout.webchat__stacked-layout--hide-avatar
  .webchat__stacked-layout__message,
.webchat--css-fmfny-1vap6r.webchat__stacked-layout.webchat__stacked-layout--show-avatar
  .webchat__stacked-layout__message,
.webchat--css-fmfny-1vap6r.webchat__stacked-layout.webchat__stacked-layout--hide-nub
  .webchat__stacked-layout__message,
.webchat--css-fmfny-1vap6r.webchat__stacked-layout.webchat__stacked-layout--show-nub
  .webchat__stacked-layout__message {
  max-width: 50%;
}

.chatbot-reference-linkcontent {
  color: var(--iveco-blue);
  display: block;
}

.chatbot-reference > a {
  text-decoration: none;
}

.webchat__icon-button .webchat__icon-button__shade {
  border-bottom-right-radius: 13px !important;
  inset: 4px !important;
  transition: background 0.2s;
}

.webchat__icon-button {
  cursor: pointer;
}

.webchat__scroll-to-end-button {
  left: 0;
  right: 0 !important;
  bottom: 0 !important;
  background-color: var(--iveco-blue) !important;
  color: var(--white) !important;
  border-radius: 0px !important;
  cursor: pointer;
  transition: background-color 0.2s;
}

.webchat__scroll-to-end-button:hover {
  background-color: var(--iveco-blue-dark) !important;
}

.webchat__basic-transcript:focus .webchat__basic-transcript__focus-indicator,
.webchat__basic-transcript
  .webchat__basic-transcript__terminator:focus
  + .webchat__basic-transcript__focus-indicator {
  border-color: transparent !important;
}

.webchat__basic-transcript:focus-visible
  .webchat__basic-transcript__focus-indicator,
.webchat__basic-transcript
  .webchat__basic-transcript__terminator:focus-visible
  + .webchat__basic-transcript__focus-indicator {
  border-color: hsla(0, 0%, 0%, 0.5) !important;
}

.webchat__basic-transcript:focus
  .webchat__basic-transcript__activity-indicator.webchat__basic-transcript__activity-indicator--focus {
  border-color: transparent !important;
}

.webchat__basic-transcript:focus-visible
  .webchat__basic-transcript__activity-indicator.webchat__basic-transcript__activity-indicator--focus {
  border-color: hsla(0, 0%, 46%, 0.5) !important;
  border-style: solid !important;
  border-width: 1px;
  box-sizing: border-box;
  height: calc(100% - 5px);
  left: 0;
  margin-bottom: 0;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  border-radius: 5px;
  width: calc(100% - 10px);
}

.disclaimer {
  font-size: 12px;
  color: #555;
  background-color: #f9f9f9;
  padding: 10px 80px 10px 80px;
  border-top: 1px solid #ddd;
  margin-top: 20px;
  text-align: center;
}

/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
