.chat-space-wrapper {
  flex-grow: 2;
  background: #F4F4F4;
}

.key-bind-wrapper {
  height: 100%;
  display: flex;
  flex-flow: column;
}

.new-chat {
  position: absolute;
  right: var(--side-offset);
  cursor: pointer;
  z-index: 1;
}

.new-chat:before {
  content: "";
  background: var(--white);
  inset: -10px;
  position: absolute;
  aspect-ratio: 1 / 1;
  z-index: -1;
  border-radius: 50%;
  opacity: 0;
  transition: opacity 0.3s;
}

.new-chat:hover::before {
  opacity: 1;
}
